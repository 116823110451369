import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getCookie, setCookie } from '../util/cookie';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/HomeActions';
import { formatDate } from '../util/dates';
import { getKeyByValue } from '../util/misc';
import { pageViewGA } from '../services/GoogleAnalytics';
import StoreDetail from '../components/StoreDetail';
import Loader from '../components/Loader';
import PriorityCard from '../components/Card/PriorityCard';
import PriorityCardDigital from '../components/PriorityCard/PriorityCardDigital';
import SectionHeader from '../components/SectionHeader';
import CardSlider from '../components/CardSlider';
import CarouselCard from '../components/Card/CarouselCard';
import TradeCard from '../components/Card/TradeCard';
import PromptCard from '../components/Card/PromptCard';
import TradeClubCard from '../components/TradeClub/TradeClubCard';
import StickyNavigation from '../components/StickyNavigation';

class HomeContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPriorityCardClosed: false,
      isPromptLbpCardClosed: false,
      isTradeClubPromptClosed: false,
    };

    this.customerTypes = {
      LBPBuilder: 4,
      UnlicensedBuilder: 3,
      SeriousDIYer: 1,
      'Farm/LifestyleBlockOwner': 2,
    };
  }

  componentDidMount() {
    const { actions } = this.props;

    if (getCookie('hidePriorityCard') === 'true') {
      this.setState({ isPriorityCardClosed: true });
    }

    if (getCookie('PromptLbpCard') === 'true') {
      this.setState({ isPromptLbpCardClosed: true });
    }
    
    actions.loadHomePage();
  }

  renderPriorityCard() {
    const { profile } = this.props;
    const PriorityCardNumber = profile.PriorityCardNumber
      ? profile.PriorityCardNumber
      : null;
    const firstName =
      profile.Personal && profile.Personal.FirstName
        ? profile.Personal.FirstName
        : null;
    const lastName =
      profile.Personal && profile.Personal.LastName
        ? profile.Personal.LastName
        : null;

    const handlePriorityCardClose = () => {
      setCookie('hidePriorityCard', 'true', '315360000');
      this.setState({ isPriorityCardClosed: true });
    };

    if (PriorityCardNumber !== 0 && PriorityCardNumber !== null) {
      return (
        <PriorityCardDigital
          headingMain="your digital"
          headingSub="priority card"
          description="Scan at the checkout"
          PriorityCardNumber={PriorityCardNumber}
          firstName={firstName}
          lastName={lastName}
        />
      );
    } else if (!this.state.isPriorityCardClosed) {
      return (
        <PriorityCard
          headingMain="have an itm"
          headingSub="priority card?"
          description="Link your Priority Card, or sign up for exclusive in-store deals."
          handleCloseClick={handlePriorityCardClose}
        />
      );
    }
  }

  renderPopularRewards() {
    const {
      homePage: { popularRewards },
    } = this.props;

    const heading =
      popularRewards.PageInfo && popularRewards.PageInfo.Title
        ? popularRewards.PageInfo.Title
        : null;
    const Subtitle =
      popularRewards.PageInfo && popularRewards.PageInfo.Subtitle
        ? popularRewards.PageInfo.Subtitle
        : null;
    //const link = popularRewards.PageInfo && popularRewards.PageInfo.Link ? popularRewards.PageInfo.Link : null;
    const items = popularRewards.Items ? popularRewards.Items : [];

    return (
      <>
        <SectionHeader
          heading={heading}
          description={Subtitle}
          seeAllUrl="https://tradeclub.itm.co.nz/login"
        />
        <CardSlider>
          {items.map((card) => {
            return (
              <TradeCard
                key={card.NodeID}
                image={card.Image}
                headingOne={card.Line1Title}
                headingTwo={card.Line2Title}
                description={card.Description}
                linkText="Learn more"
                linkUrl={card.CTALink}
              />
            );
          })}
        </CardSlider>
      </>
    );
  }

  // renderTradeDeals() {
  //   const {
  //     homePage: { tradeDeals },
  //   } = this.props;

  //   return (
  //     <>
  //       <SectionHeader heading="Trade Deals" seeAllUrl="/deals/" />
  //       <CardSlider>
  //         {tradeDeals.map((card) => {
  //           return (
  //             <TradeCard
  //               key={card.NodeID}
  //               image={card.Image}
  //               headingOne={card.Line1Title}
  //               headingTwo={card.Line2Title}
  //               description={card.ShortDescription}
  //               linkText="Learn more"
  //               linkUrl={`/deals/article?id=${card.NodeID}`}
  //             />
  //           );
  //         })}
  //       </CardSlider>
  //     </>
  //   );
  // }

  renderLatestOffer() {
    const {
      homePage: { latestOffers },
    } = this.props;

    return (
      <>
        <SectionHeader heading="Latest Offers" seeAllUrl="/diy/" />
        <CardSlider>
          {latestOffers.map((card) => {
            return (
              <TradeCard
                key={card.NodeID}
                image={card.Image}
                headingOne={card.Line1Title}
                headingTwo={card.Line2Title}
                description={card.ShortDescription}
                linkText="Learn more"
                linkUrl={`/diy/article?id=${card.NodeID}`}
              />
            );
          })}
        </CardSlider>
      </>
    );
  }

  renderLocalNews() {
    const {
      homePage: { newsPosts },
    } = this.props;
    //const heading = newsPosts?.PageInfo?.Title || 'Building Business News';
    const heading = 'Building Business News -joel test';
    const items = newsPosts?.Items || [];

    return (
      <>
        <SectionHeader heading={heading} seeAllUrl="/news/" />
        <CardSlider>
          {items.map((card) => {
            return (
              <CarouselCard
                key={card.NodeID}
                linkUrl={`/news/article?id=${card.NodeID}`}
                heading={card.ArticleName}
                publishedDate={formatDate(card.PublishDate)}
                subheading={card.ArticleTitle}
                image={card.HeroImage}
              />
            );
          })}
        </CardSlider>
      </>
    );
  }

  renderPromptlbp() {
    const handlePromptLbpCardClose = () => {
      setCookie('PromptLbpCard', 'true', '315360000');
      this.setState({ isPromptLbpCardClosed: true });
    };

    return (
      <>
        {!this.state.isPromptLbpCardClosed && (
          <PromptCard
            heading="Set up your <br/> LBP profile"
            description="Record learning activities and stay on top of your LBP license requirements."
            handleCloseClick={handlePromptLbpCardClose}
            link='/lbp-diary'
          />
        )}
      </>
    );
  }

  renderTradeclubCard() {
    const {profile} = this.props;

    const totalPoints = profile?.TradeClub?.TotalPoints;
    const lastUpdatedOn = profile?.TradeClub?.LastUpdatedOn;
    const expiringPointsForThisMonth = profile?.TradeClub?.ExpiringPointsForThisMonth || 0;
    const earnedPointsForThisMonth = profile?.TradeClub?.EarnedPointsForThisMonth || 0;
    const memberNo = profile?.TradeClub?.MemberNo;

    if (memberNo) {
      return (
        <TradeClubCard
          totalPoints={totalPoints}
          EarnedPointsForThisMonth={earnedPointsForThisMonth}
          ExpiringPointsForThisMonth={expiringPointsForThisMonth}
          LastUpdatedOn={lastUpdatedOn}
        />
      );
    } else if (!this.state.isTradeClubPromptClosed) {
      return (
        <PromptCard
          heading="Trade Club rewards"
          handleNextClick={() => {
            window.open('https://tradeclub.itm.co.nz', '_blank');
          }}
          description="Earn rewards points on all in-store purchases. To join Trade Club please visit your local ITM store."
          handleCloseClick={this.handlePromptCloseClick}
        />
      );
    }
  }

  renderHomePage() {
    const { profile, user } = this.props;

    const storeName = profile?.Store?.Name;
    const storeID = profile?.Store?.NodeID;
    const userName = user?.firstName;
    const userType = profile?.UserSettings?.CustomerType || '';
    const tradeClubNo = profile?.TradeClub?.MemberNo || '';
    const lbpNo = profile?.LBP?.LBPNumber || '';
    const customerType = getKeyByValue(this.customerTypes, userType);

    // Use this object to render the elements conditionally on page load
    const toggleElement = {
      tradeclubCard: false,
      promptlbp: false,
      priorityCard: false,
      localNews: true,
      // tradeDeals: false,
      latestOffer: false,
      popularRewards: false,
    };

    // Check if user is of type SeriousDIYer/Farm-LifestyleBlockOwner
    if (
      userType === this.customerTypes['SeriousDIYer'] ||
      userType === this.customerTypes['Farm/LifestyleBlockOwner']
    ) {
      toggleElement.priorityCard = true;
      toggleElement.latestOffer = true;
      // toggleElement.tradeDeals = false;
    }
    // Check if user is of type UnlicensedBuilder
    else if (userType === this.customerTypes['UnlicensedBuilder']) {
      toggleElement.tradeclubCard = true;
      // toggleElement.tradeDeals = false;
      // Check if user has trade club number and show popular rewards
      if (tradeClubNo !== '') {
        toggleElement.popularRewards = true;
      }
    }
    // Check if user is of type LBPBuilder
    else if (userType === this.customerTypes['LBPBuilder']) {
      toggleElement.tradeclubCard = true;
      // toggleElement.tradeDeals = false;
      // Check if user has lbp number and promptlbp
      if (lbpNo === '') {
        toggleElement.promptlbp = true;
      }
      // Check if user has trade club number and show popular rewards
      if (tradeClubNo !== '') {
        toggleElement.popularRewards = true;
      }
    }

    if (customerType) {
      pageViewGA('homepage');
    }

    return (
      <>
        <StoreDetail
          storeName={storeName}
          userName={userName}
          storeID={storeID}
        />
        <div className="home-page">
          {toggleElement.tradeclubCard && this.renderTradeclubCard()}
          {toggleElement.promptlbp && this.renderPromptlbp()}
          {toggleElement.priorityCard && this.renderPriorityCard()}
          <div className="home-page__sliders">
            {toggleElement.popularRewards && this.renderPopularRewards()}
            {toggleElement.localNews && this.renderLocalNews()}
            {/* {toggleElement.tradeDeals && this.renderTradeDeals()} */}
            {toggleElement.latestOffer && this.renderLatestOffer()}
          </div>
        </div>
        <StickyNavigation activeTabName="home" />
      </>
    );
  }

  render() {
    const { loading } = this.props;

    return (
      <>
        {loading && <Loader />}
        {!loading && this.renderHomePage()}
      </>
    );
  }
}
HomeContainer.propTypes = {
  homePage: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  actions: PropTypes.object
};

HomeContainer.defaultProps = {
  homePage: {},
  user: {},
  profile: {},
  loading: false,
};

const mapStateToProps = (state) => {
  const {
    home: { loading, homePage },
    auth: { user },
    account: { profile },
  } = state;

  return { loading, homePage, user, profile };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
